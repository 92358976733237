import { Link } from "gatsby"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import parse from "html-react-parser"
import "./TileBlock.scss"
import { GetPageURL } from "../common/utils"
import PlayVideo from "../PlayVideo/PlayVideo"

const TileBlockModule = ({ module }) => {
  const [isPlay, setPlay] = useState(false)

  return (
    <div className="tile-block-module-wrapper section-p">
      <Container
        className={`tile-block-container ${
          module.layout_type === "left_small_width" ? "" : "img-right"
        }`}
      >
        <div className="img-block rounded">
          <GetGatsbyImage
            image={module.media_block.media_file}
            fallbackalt={module.text_block?.title}
          />
          {module.image_tag && <div className="tag">{module.image_tag}</div>}
          {module.video_url && (
            <a
              className="btn-play icon icon-play-white"
              onClick={() => setPlay(true)}
            ></a>
          )}
        </div>
        <div className="content">
          <h3 className="title">{module.text_block?.title}</h3>
          {module.text_block?.description && (
            <div className="description">
              {parse(module.text_block?.description)}
            </div>
          )}
          {module.Button_CTA && (
            <Link
              target={module.Button_CTA.target === "new_window" ? "_blank" : ""}
              to={GetPageURL(module.Button_CTA?.menu_item)}
              className="cta-link"
            >
              {module.Button_CTA.label}
            </Link>
          )}
        </div>
      </Container>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={module.video_url}
          htmlink={""}
        />
      )}
    </div>
  )
}

export default TileBlockModule
