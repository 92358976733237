import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';

const postsPerPage = 6;
let arrayForHoldingPosts = [];

const NewsArticles = ({newsdata}) => {
 
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(6);
  
    const newsArray = newsdata.slice(2);

    const loopWithSlice = (start, end) => {
      const slicedPosts = newsArray.slice(start, end);
      arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
      setPostsToShow(arrayForHoldingPosts);
    };
  
    useEffect(() => {
        loopWithSlice(0, postsPerPage);
        arrayForHoldingPosts = []
    }, []);
  
    const handleShowMorePosts = () => {
      loopWithSlice(next, next + postsPerPage);
      setNext(next + postsPerPage);
    };

    return (
        <section className="news-articles-wrapper news-landing-articles">
            <Container>
                <Row className="justify-content-center">
                    <Col>
                        <Row>
                            <Col lg={12}>
                                <h2 className="text-start">More news articles</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <ul className="news-articles-list">
                                    {
                                        postsToShow.map((data, i) => {
                                            return (
                                                <li>
                                                    <div className="news-articles-link">
                                                        <Link to={"/latest-news/"+data?.slug}>{data?.title}</Link>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="news-articles-border-line"></div>
                                {
                                    postsToShow.length >= newsArray.length ? "" :
                                    <Button variant="" onClick={handleShowMorePosts} className="btn-pink m-auto">Load More</Button>
                                }
                            </Col>
                        </Row>
                    </Col>  
                </Row>
            </Container>
        </section>
    )
}

export default NewsArticles