import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby";
import './GetInTouchBottom.scss';
import ContactForm from '../forms/contact';
import parse from 'html-react-parser';
function GetInTouchBottom(props) {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        globalModule {
            getin_touch_form_bottom {
              Title
              Content
              Background_Image {
                url
                alternativeText
              }
            }
        }
      }
    }
    `)
  const formdata = data.glstrapi.globalModule.getin_touch_form_bottom;
  const bannerdata = props.banner;
  return (
    <section className='section-p section-btm-form' style={{
        backgroundImage: `url(${
            formdata.Background_Image && formdata.Background_Image.url
        })`,
      }}>
        <div className={`${props.layout == "form_template" ? "contact-form-page" : "contact-form-module"}`}>
        <Container>
            <Row className={`${props.layout == "form_template" ? "align-items-center" : ""}`}>
                <Col lg={`6`}>
                    <div className='frm-wrap-left'>
                        <h4>{bannerdata?.heading ? bannerdata.heading :formdata.Title}</h4>
                        {bannerdata?.title ?
                          <h2>{bannerdata.title}</h2> : ""
                        }
                        {bannerdata?.banner_content ? 
                          parse(bannerdata.banner_content)
                          :
                          parse(formdata.Content)
                        }                                                
                    </div>
                </Col>
                <Col lg={`1`}></Col>
                <Col lg={`5`} className="formcol">
                <div className='frm-wrap-right'>
                    <h4>FEEL FREE TO GET IN TOUCH</h4>
                    <h2>Your Details</h2>
                    <ContactForm />
                </div>
                </Col>
            </Row>
        </Container>
        </div>
    </section>
  )
}

export default GetInTouchBottom