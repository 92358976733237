import React from "react"
import { Container } from "react-bootstrap"
import "./GridBlocksModule.scss"
import parse from "html-react-parser"
import GridBlock from "./GridBlock"

const GridBlocksModule = ({ module }) => {
  return (
    <div className="grid-block-wrapper section-p">
      <Container className="grid-block-container">
        {module.Grid_Title && (
          <div className="content">
            <h2 className="title">{parse(module.Grid_Title)}</h2>
            <p className="description">{parse(module.Grid_Description)}</p>
            {/* <p className="description">{module.Grid_Description}</p> */}
          </div>
        )}
        <div className="grid-block">
          {module.Grid_Items?.length > 0 &&
            module.Grid_Items.map((item, index) => (
              <GridBlock item={item} index={index} />
            ))}
        </div>
      </Container>
    </div>
  )
}

export default GridBlocksModule
