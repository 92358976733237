import React from 'react'
import { Container } from 'react-bootstrap'
import parse from 'html-react-parser'
import { useStaticQuery, graphql } from "gatsby";
import {AltSiteName} from '../common/site/utils'
import './Partners.scss'
import CommonSlider from '../CommonSlider/PartnerSlider'
import { Link } from "gatsby"
function Partners(props) {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        partners {
            Name
            Logo {
              url
              alternativeText
            }
          }
      }
    }
    `)
    const partners = data.glstrapi.partners;
  return (
    <section className='section-p partners-section partners-slider-wrap'>
        <Container>
            <h2><Link to="/partners/">{props.title}</Link></h2>
            {props.description && <div className='partner-desc text-center'>
                {parse(props.description)}
            </div>}
            <div className='partners-wrap'>
              <CommonSlider>
                {partners.map((item) => (
                    <div className='partners-item'>
                        <img src={item?.Logo?.url} alt={item?.Logo?.alternativeText ? item?.Logo?.alternativeText+' '+AltSiteName : item?.Name+' '+AltSiteName} />
                    </div>    
                ))}
              </CommonSlider>
            </div>
        </Container>
    </section>
  )
}

export default Partners