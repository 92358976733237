import React from "react"
import { Accordion } from "react-bootstrap"
import parse from "html-react-parser"

const AccordianItem = ({ accordion, eventKey }) => {
  if (!accordion) return null

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <h3>{accordion.FAQ_Title}</h3>
      </Accordion.Header>
      {accordion.FAQ_Content && <Accordion.Body>{parse(accordion.FAQ_Content)}</Accordion.Body>}
    </Accordion.Item>
  )
}

export default AccordianItem
