import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { GetPageURL } from "../common/utils"
import "./SubmenuGridBlock.scss"

const SubmenuGridBlock = ({ module }) => {

  return (
    <div className="submenugrid-wrapper">
      <Container className="submenugrid-container">
        {module?.Grid_Items?.length > 0 &&
          module?.Grid_Items.map(item => (
            <Link
              target={item?.target === "new_window" ? "_blank" : ""}
              to={GetPageURL(item.grid_link)}
              className={`grid-item rounded img-zoom`}
            >
              {item.Grid_Image && (
                <>
                  <GetGatsbyImage
                    image={item.Grid_Image}
                    fallbackalt={item.Heading}
                    className="block-img"
                  />
                  {/* <img src={Grid_Image?.url} alt="" className="block-img" /> */}
                  <div className="overlay"></div>
                </>
              )}
              <div className="block-content">
                <p className="heading">{item.Heading}</p>
                <p className="title">{item.Title}</p>
              </div>
            </Link>
          ))}
      </Container>
    </div>
  )
}

export default SubmenuGridBlock
