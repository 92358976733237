import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./TermsContentModule.scss"
import { Link } from "gatsby"
import $ from "jquery";
import { scrollToElement } from "../utils/utils"

const TermsContentModule = ({ module }) => {

  useEffect(() => {
    $('.terms-cta').on('click', function (e) {
      $('.terms-cta').removeClass('active');
      $(this).addClass('active');
    })
  });

  const termsContent =
    module?.Terms_Content?.length > 0 && module?.Terms_Content

  if (!termsContent) return null

  return (
    <div className="terms-content-wrapper section-p">
      <Container className="terms-content-container">
        <div className="terms-cta-section">
          {termsContent.map((content, i) => (
            <button
              className={`terms-cta ${i === 0 ? "active" : ""}`}
              onClick={() => {
                const element = document.getElementById(content.title)
                if (element) {
                  scrollToElement(element, 140)
                }
              }}
            >
              {content.title}
            </button>
          ))}
        </div>
        <div className="terms-content">
          {termsContent.map((content, i) => (
            <div className={"content-" + (i + 1)} id={content.title}>
              {content.description && parse(content.description)}
              {termsContent.length !== i + 1 && (
                <div className="block-devider"></div>
              )}
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default TermsContentModule
