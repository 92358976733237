import React, { useState, useMemo } from 'react';
import { Container, Col, Row } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import GetGatsbyImage from '../common/site/get-gatsby-image'
import './Teams.scss'

const TeamsLanding = () => {
    const data = useStaticQuery(graphql`
    query GetTeamsData {
        glstrapi {
          teams {
            id
            title
            name
            slug
            designation
            category
            tile_image {
              url
              alternativeText
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 600
                    width: 568
                    layout: FIXED
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
          }
        }
      }
  `)
  const teamData = data.glstrapi.teams;
  const [selectedCategory, setSelectedCategory] = useState('Executive_Team');
  const categories = ['Executive_Team', 'Advisory_Board'];

  // Memoize the filtered team members to avoid unnecessary re-renders
  const filteredTeamMembers = useMemo(() => {
      return teamData.filter(item => item.category === selectedCategory);
  }, [selectedCategory, teamData]);

    return (
      <section className='team-section team-listing-page section-p pt-0'>
        <Container>
          <Row className='align-items-center team-header'>
            <ul className="category-filter d-flex align-items-center justify-content-center">
              {categories.map(category => (
                <li key={category}>
                    <button onClick={() => setSelectedCategory(category)} className={`btn ${selectedCategory === category ? "active-btn btn-green" : ""}`}>
                      {category.replace(/_/g, ' ')}
                    </button>
                </li>
              ))}
            </ul>
          </Row>
          <Row className='align-items-center'>
            {filteredTeamMembers.map((item, index) => (
              <Col lg={4} key={item.id || index} className="team-col">
                <div className='team-item'>
                  <Link to={"/meet-the-team/"+item?.slug}>
                    <div className="team-block-img"><GetGatsbyImage image={item?.tile_image} fallbackalt={item?.name} className="img-fluid member-img" /></div>
                    <div className="team-block-content">
                      <h4 className="team-title">{item?.name}</h4>
                      <p className="team-heading">{item?.designation}</p>
                    </div>
                  </Link>
                </div>    
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    )
}

export default TeamsLanding