import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import NewsCard from "../NewsCard/NewsCard";
import NewsArticles from "../NewsArticles/NewsArticles";

const NewsLanding = () => {
    const data = useStaticQuery(graphql`
    query GetNewsData {
        glstrapi {
          blogs {
            blog_category {
              category_label
            }
            createdAt
            tile_image {
              url
              url_sharp {
                id
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    height: 417
                    width: 580
                    layout: FIXED
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            title
            slug
          }
        }
      }
  `)

    const newsdata = data.glstrapi.blogs;
    const sortednews =newsdata?.sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt))
    
    return (
        <>
            <NewsCard newsdata={sortednews} />
            <NewsArticles newsdata={sortednews} />
        </>
    )
}

export default NewsLanding