import clsx from "clsx"
import Fuse from "fuse.js"
import { graphql, Link, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Accordion, Container } from "react-bootstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Select from "react-select"
import serachIcon from "../../images/icons/icon-search.svg"
import closeIcon from "../../images/icons/icon-close.svg"
import AccordianItem from "./AccordianItem"
import "./FAQ.scss"

function FAQ(props) {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        faqs {
          Title
          FAQ_Listing {
            FAQ_Title
            FAQ_Content
          }
          faq_category {
            Name
          }
        }
      }
    }
  `)

  const faqs = data.glstrapi.faqs
  const allFaqs = faqs?.map(item => item.FAQ_Listing?.map(faq => faq)).flat()

  const [faqCat, setFaqCat] = useState(
    props.faqcat?.Name ? props.faqcat?.Name : "Homeowners"
  )

  const [searchMode, setSearchMode] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [resultFaqs, setResultFaqs] = useState(allFaqs)

  let selectvalues = []
  faqs &&
    faqs.map(val =>
      selectvalues.push({
        value: val.faq_category.Name,
        label: val.faq_category.Name,
      })
    )

  const handleSearch = val => {
    setSearchText(val)

    // if search string is empty return all faqs
    if (val === "") return setResultFaqs(allFaqs)

    // initiate fuse
    const fuse = new Fuse(allFaqs, {
      keys: ["FAQ_Title", "FAQ_Content"],
      ignoreLocation: true,
      threshold: 0.4,
      includeScore: true,
    })

    // search for the val and only get the item
    const result = fuse.search(val).map(item => item.item)
    setResultFaqs(result)
  }

  const SearchInput = (
    <>
      <img src={serachIcon} alt="search-icon" className="search-icon" />
      {searchMode && (
        <>
          <input
            type="text"
            placeholder="Start typing to search"
            className="search-input"
            value={searchText}
            onChange={e => handleSearch(e.target.value)}
          />
          <img
            src={closeIcon}
            alt="search-icon"
            className="search-icon"
            onClick={() => {
              setFaqCat("Homeowners")
              setSearchMode(false)
            }}
          />
        </>
      )}
    </>
  )

  return (
    <div>
      <section className="section-p faq-section">
        <Container
          className={clsx("text-center m-auto faq-section-container", {
            "search-mode": searchMode,
          })}
        >
          <div className="head-faq">
            {props.title ? <h2>{props.title}</h2> : ""}
            {props.description ? parse(props.description) : ""}
          </div>
          <div className="d-md-none mobile-tab-dropdown">
            {!searchMode && (
              <Select
                options={selectvalues}
                values={[]}
                isSearchable={false}
                classNamePrefix={"select-opt"}
                className="select-control"
                defaultValue={{
                  label: props.faqcat?.Name
                    ? props.faqcat.Name
                    : selectvalues.length > 0 && selectvalues[0].label,
                  value: props.faqcat?.Name
                    ? props.faqcat.Name
                    : selectvalues.length > 0 && selectvalues[0].value,
                }}
                onChange={e => setFaqCat(e.value)}
                // menuIsOpen={true}
              />
            )}
            <div
              className={clsx("search-tab", { "search-open": searchMode })}
              onClick={() => {
                if (!searchMode) {
                  setSearchMode(true)
                  setFaqCat("search")
                }
              }}
            >
              {SearchInput}
            </div>
          </div>
          <Tabs
            id="uncontrolled-tab-example"
            className={clsx("mb-3 faq-tabs d-none d-md-flex")}
            onSelect={k => {
              if (k === "search" && !searchMode) {
                setSearchMode(true)
              }
              if (!searchMode) setFaqCat(k)
            }}
            activeKey={faqCat}
          >
            {faqs.map((item, i) => (
              <Tab
                eventKey={item.faq_category.Name}
                title={item.faq_category.Name}
                tabClassName={clsx("category-tab", { "d-none": searchMode })}
              >
                {props.class == "faq-page" ? (
                  <div className="accordian-section">
                    <Accordion flush>
                      {item.FAQ_Listing?.map((accordion, i) => (
                        <AccordianItem
                          accordion={accordion}
                          eventKey={i}
                          key={"acoordian" + i}
                        />
                      ))}
                    </Accordion>
                  </div>
                ) : (
                  <div className="accordian-section">
                    <Accordion flush>
                      {item.FAQ_Listing.slice(0, 5)?.map((accordion, i) => (
                        <AccordianItem
                          accordion={accordion}
                          eventKey={i}
                          key={"acoordian" + i}
                        />
                      ))}
                    </Accordion>
                    <div className="view-all-faq">
                      <Link to="/faqs/">View all FAQ's</Link>
                    </div>
                  </div>
                )}
              </Tab>
            ))}

            <Tab
              eventKey={"search"}
              title={SearchInput}
              tabClassName="search-tab"
            >
              <div className="accordian-section">
                <Accordion flush>
                  {resultFaqs?.map((accordion, i) => (
                    <AccordianItem
                      accordion={accordion}
                      eventKey={i}
                      key={"acoordian" + i}
                    />
                  ))}
                </Accordion>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </section>
    </div>
  )
}

export default FAQ
