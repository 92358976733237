import parse from "html-react-parser"
import { Link } from "gatsby"
import React, { useState } from "react"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { GetPageURL } from "../common/utils"
import PlayVideo from "../PlayVideo/PlayVideo"

const GridBlock = ({ item, index }) => {
  const [isPlay, setPlay] = useState(false)

  const { Grid_Image, Heading, Title, grid_link, id, video_url } = item

  return (
    <div className={`grid-item rounded grid-item-${index + 1} img-zoom`}>
      {video_url && (
        <i
          className="btn-play icon icon-play-white"
          onClick={() => setPlay(true)}
        ></i>
      )}
      <>
        {Grid_Image && (
          <Link
            target={item?.target === "new_window" ? "_blank" : ""}
            to={GetPageURL(item.grid_link)}
          >
            <GetGatsbyImage
              image={Grid_Image}
              fallbackalt={Heading}
              className="block-img"
            />
            {/* <img src={Grid_Image?.url} alt="" className="block-img" /> */}
            <div className="overlay"></div>
          </Link>
        )}
        <Link
          target={item?.target === "new_window" ? "_blank" : ""}
          to={GetPageURL(item.grid_link)}
          className="block-content"
        >
          {Heading && <p className="heading">{parse(Heading)}</p>}
          <p className="title">{Title}</p>
        </Link>
        {isPlay && (
          <PlayVideo
            isOpen={isPlay}
            stopPlay={setPlay}
            videoId=""
            isCloseFunction={setPlay}
            videourl={video_url}
            htmlink={""}
          />
        )}
      </>
    </div>
  )
}

export default GridBlock
