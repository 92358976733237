import React from "react"
import Footer from "../components/Footer/Footer"
import GridBlocksModule from "../components/GridBlocksModule/GridBlocksModule"
import Header from "../components/Header/Header"
import Partners from "../components/Partners/Partners"
import PartnersListing from "../components/Partners/PartnersListing"
import BottomDarkBanner from "../components/BottomDarkBanner/BottomDarkBanner"
import GetInTouchBottom from "../components/GetInTouchBottom/GetInTouchBottom"
import ListBlocksModule from "../components/ListBlockModule/ListBlockModule"
import BannerIntro from "../components/BannerIntro/BannerIntro"
import BannerWithForm from "../components/BannerWithForm/BannerWithForm"
import TileBlockModule from "../components/TileBlock/TileBlockModule"
import FAQ from "../components/FAQ/FAQ"
import NewsLanding from "../components/NewsLanding/NewsLanding"
import TermsContentModule from "../components/TermsContentModule/TermsContentModule"
import SubmenuGridBlock from "../components/SubmenuGridBlock/SubmenuGridBlock"
import TextBlockSlider from "../components/TextBlockSlider/TextBlockSlider"
import OtherNewsArticles from "../components/NewsArticles/OtherNewsArticles"
import AcquisitionModule from "../components/AcquisitionModule/AcquisitionModule"
import TeamsLanding from "../components/Teams/TeamsLanding"

const IndexPage = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  const bannerSection = GQLPage.banner_section

  return (
    <div className={`${GQLPage.page_class ? GQLPage.page_class : ""}`}>
      <Header layout={GQLPage.layout} />

      {GQLPage.layout === "banner_with_form" && GQLPage.banner_section && (
        <BannerWithForm
          banner={GQLPage.banner_section}
          cta1={bannerSection.button_cta_1}
        />
      )}
      {GQLPage.layout === "form_template" && (
        <GetInTouchBottom
          layout={GQLPage.layout}
          banner={GQLPage.banner_section}
        />
      )}
      {(GQLPage.layout === "landing_template" ||
        GQLPage.layout === "static_template") && (
        <BannerIntro
          heading={bannerSection.heading}
          title={bannerSection.title}
          description={bannerSection.banner_content}
          cta1={bannerSection.button_cta_1}
          cta2={bannerSection.button_cta_2}
          cta3={bannerSection.button_cta_3}
          layout={GQLPage.layout}
        />
      )}

      {GQLModules.map((module, i) => {
        return (
          <>
            {module.__typename === "GLSTRAPI_ComponentComponentsGridBlock" &&
              module.Grid_Style === "Three_Column_Grids" && (
                <GridBlocksModule module={module} />
              )}

            {module.__typename === "GLSTRAPI_ComponentComponentsGridBlock" &&
              module.Grid_Style === "Submenu_Grids" && (
                <SubmenuGridBlock module={module} />
              )}

            {module.__typename === "GLSTRAPI_ComponentComponentsGridBlock" &&
              module.Grid_Style === "Default" && (
                <ListBlocksModule module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsTextBlockWithImage" && (
              <TileBlockModule module={module} />
            )}
            {module.__typename ===
              "GLSTRAPI_ComponentBlocksTextBoxInsideTextBox" && (
              <TextBlockSlider module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "partners" && (
                <Partners
                  title={module.title}
                  description={module.description}
                />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.select_module === "Partners_Listing" && (
                <PartnersListing />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "blogs" && <NewsLanding />}

            {module.__typename === "GLSTRAPI_ComponentComponentsSelectCollection" && module.choose_collection === "teams" && <TeamsLanding />}


            {module.__typename === "GLSTRAPI_ComponentModulesStepsModule" && (
              <AcquisitionModule module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "more_blogs" && (
                <OtherNewsArticles title={module.title} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectCollection" &&
              module.choose_collection === "faqs" && (
                <FAQ
                  title={module.title}
                  description={module.description}
                  faqcat={GQLPage.choose_faq_category}
                  class={GQLPage.page_class}
                />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.select_module === "Get_In_Touch_Bottom_Form" && (
                <GetInTouchBottom layout={GQLPage.layout} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.select_module === "FAQ_Listing" && (
                <FAQ class={GQLPage.page_class} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsBottomDarkBanner" && (
              <BottomDarkBanner module={module} GQLPage={GQLPage} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsTermsContentModule" && (
              <TermsContentModule module={module} />
            )}
          </>
        )
      })}
      <Footer />
    </div>
  )
}

export default IndexPage
