import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import GetGatsbyImage from "../common/site/get-gatsby-image";
import './assets/styles/_index.scss';

const NewsCard = ({newsdata}) => {
    return (
        <section className="new-card-wrapper">
            <Container>
                <Row>
                    {
                        newsdata.slice(0, 2).map((data, i) => {
                            return (
                                <Col lg={6}>
                                    <div className="news-block">
                                        <Link to={"/latest-news/"+data?.slug} className="news-img-zoom">
                                            <GetGatsbyImage
                                                image={data?.tile_image}
                                                fallbackalt={data?.title}
                                                className="img-fluid news-block-img"
                                            />
                                            <div className="news-overlay"></div>
                                            <div className="news-block-content">
                                                <p className="news-heading">{data?.blog_category?.category_label}</p>
                                                <p className="news-title">{data?.title}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

export default NewsCard