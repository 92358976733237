import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import parse from 'html-react-parser'
import { useStaticQuery, graphql } from "gatsby";
import {AltSiteName} from '../common/site/utils'
import './Partners.scss'
import CommonSlider from '../CommonSlider/CommonSlider'
function Partners(props) {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        partners {
            Name
            Logo {
              url
              alternativeText
            }
          }
      }
    }
    `)
    const partners = data.glstrapi.partners;
  return (
    <section className='section-p partners-section p-0 partners-listing-page'>
        <Container>
            <div className='partners-wrap'>
                <Row className='align-items-center'>
                    {partners.map((item) => (
                        <Col lg={3} md={4} className="partners-col">
                        <div className='partners-item'>
                            <img src={item?.Logo?.url} alt={item?.Logo?.alternativeText ? item?.Logo?.alternativeText+' '+AltSiteName : item?.Name+' '+AltSiteName} />
                        </div>    
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    </section>
  )
}

export default Partners