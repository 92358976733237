import React, { useState } from "react"
import { Container, Col, Row, Form } from "react-bootstrap"
import { Link } from "gatsby"
import "./assets/styles/_index.scss"
import Img1 from "../../images/person-1.png"
import Img2 from "../../images/person-2.png"
import Img3 from "../../images/person-3.png"
import Img4 from "../../images/person-4.png"
import Img5 from "../../images/person-5.png"
import parse from "html-react-parser"
import InstantQuoteForm from "../forms/instantquote"
import PlayVideo from "../PlayVideo/PlayVideo"
import poundIcon from "../../images/icons/icon-pound.svg"
import locationIcon from "../../images/icons/icon-location.svg"
import AddressLookup from "./AddressLookup"
const BannerWithForm = ({ banner }) => {
  const [isPlay, setPlay] = useState(false)
  return (
    <section className="banner-with-form-wrapper" id="home-page-form">
      <Container>
        <Row className="d-flex align-items-center">
          <Col lg={5} className="banner-left-side">
            <h1 className="text-start">{parse(banner.title)}</h1>
            {banner.banner_content && parse(banner.banner_content)}
            <button
              onClick={e => {
                setPlay(true)
              }}
              className="btn btn-green"
            >
              <span>{banner?.button_cta_1?.label}</span>
            </button>
          </Col>
          <Col lg={1}></Col>
          <Col lg={6} className="banner-right-side">
            <div className="d-flex">
              <ul className="list-unstyled d-lg-block d-none">
                <li>
                  <img src={Img1} />
                </li>
                <li>
                  <img src={Img2} />
                </li>
                <li>
                  <img src={Img3} />
                </li>
                <li>
                  <img src={Img4} />
                </li>
                <li>
                  <img src={Img5} />
                </li>
              </ul>

              <div className="banner-form-wrapper w-100">
                <div className="form-title-sm">INSTANT FREE QUOTE</div>
                <h3>How much to Guarantee my Property Sale?</h3>
                <InstantQuoteForm />
                {/* <Form>
                                    <Form.Group className="banner-form-input">
                                        <Form.Control type="text" placeholder="Agreed sale price" className='price-input' />
                                    </Form.Group>
                                    <Form.Group className="banner-form-input">
                                        <AddressLookup />
                                    </Form.Group>
                                    <Form.Group className="banner-form-input">
                                        <Form.Control type="email" placeholder="Email Address" className='email-input'/>
                                    </Form.Group>
                                    <Link to="#" className="btn btn-pink w-100">Instant Free quote</Link>
                                </Form> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {isPlay && (
        <PlayVideo
          isOpen={isPlay}
          stopPlay={setPlay}
          videoId=""
          isCloseFunction={setPlay}
          videourl={banner?.button_cta_1?.custom_link}
          htmlink={""}
        />
      )}
    </section>
  )
}

export default BannerWithForm
