import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import ellipse from "../../images/icons/ellipse.svg"
import ellipsePink from "../../images/icons/ellipse-pink.svg"

import logo from "../../images/icons/clozesure-icon-outline.svg"
import "./AcquisitionModule.scss"

const AcquisitionModule = ({ module }) => {
  const [startPinkIndex, setStartPinkList] = useState(0)

  useEffect(() => {
    const elementFirst = document.getElementById("step-block-container-0")
    const elementLast = document.getElementById(
      `step-block-container-${steps.length - 1}`
    )
    const elementLine = document.getElementById("line")
    const elementContainer = document.getElementById(
      "acquisition-module-container"
    )
    const elementContainerHeight = elementContainer?.clientHeight

    const reductionHeight =
      elementLast.offsetHeight / 2 + elementFirst.offsetHeight / 2

    elementLine.style.top = elementFirst.offsetHeight / 2 + "px"

    elementLine.style.height = elementContainerHeight - reductionHeight + "px"

    const index = steps.findIndex(step => step.show_logo)
    setStartPinkList(index)
  }, [])

  const steps = module?.steps
  const contents = module?.contents

  return (
    <div className="acquisition-module-wrapper">
      <div id="acquisition-module-container">
        <div className="step-block-wrapper">
          <div id="line" className="lines"></div>
          {steps?.map((step, i) => {
            return (
              <div
                id={"step-block-container-" + i}
                className={`step-block-container ${
                  step.show_logo ? "step-block-container-highlight" : ""
                }`}
              >
                <img
                  src={startPinkIndex <= i ? ellipsePink : ellipse}
                  alt=""
                  className="list-icon"
                />
                <div className="step-block">
                  <p className="title">{step.title}</p>
                  {step.description && (
                    <div className="description">{parse(step.description)}</div>
                  )}
                </div>
                {step.show_logo && (
                  <img src={logo} alt="" className="badge-icon" />
                )}
              </div>
            )
          })}
        </div>
      </div>
      <Container className="content-container">
        {contents?.map(content => (
          <div className="content-block">
            <h2 className="title">{content.title}</h2>
            {content.description && (
              <div className="description">{parse(content.description)}</div>
            )}
          </div>
        ))}
      </Container>
    </div>
  )
}

export default AcquisitionModule
