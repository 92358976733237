import React from "react"
import "../scss/global.scss"
import { graphql } from "gatsby"
import CommonTemplate from "./common-template"
import SEO from "../components/Seo/seo"
function DefaultTemplate(props) {
  const GQLPage = props.data?.glstrapi?.page
  const GQLModules = GQLPage.add_components
  const locationState = props.location.state

  return (
    <>
      <SEO
        title={GQLPage.seo_meta ? GQLPage.seo_meta.title : GQLPage.title}
        description={GQLPage.seo_meta ? GQLPage.seo_meta.description : ""}
      />

      <CommonTemplate
        GQLPage={GQLPage}
        GQLModules={GQLModules}
        locationState={locationState}
      />
    </>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query GetArticle($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        banner_section {
          banner_content
          banner_image {
            url
          }
          button_cta_1 {
            theme
            target
            label
            id
            custom_link
            menu_item {
              id
              label
              link_type
              slug
              parent {
                slug
                label
                parent {
                  slug
                  label
                }
              }
            }
          }
          button_cta_2 {
            custom_link
            id
            label
            target
            theme
            menu_item {
              id
              label
              link_type
              slug
              parent {
                slug
                label
                parent {
                  slug
                  label
                }
              }
            }
          }
          button_cta_3 {
            custom_link
            id
            label
            target
            theme
            menu_item {
              id
              label
              link_type
              slug
              parent {
                slug
                label
                parent {
                  slug
                  label
                }
              }
            }
          }
          content
          heading
          id
          show_reviews
          show_search
          title
        }
        choose_form
        content
        id
        layout
        title
        choose_faq_category {
          id
          Name
        }
        seo_meta {
          description
          id
          title
          keywords
          image {
            url
          }
        }
        publish
        page_class
        popular_searches {
          id
        }
        alias
        add_components {
          ... on GLSTRAPI_ComponentComponentsSelectCollection {
            id
            __typename
            choose_collection
            title
            description
          }
          ... on GLSTRAPI_ComponentComponentsBottomDarkBanner {
            __typename
            id
            Title
            Heading
            CTA_Label
            CTA_Link {
              slug
              label
            }
          }

          ... on GLSTRAPI_ComponentComponentsSelectGlobalModules {
            id
            __typename
            select_module
          }

          ... on GLSTRAPI_ComponentComponentsTermsContentModule {
            __typename
            id
            Terms_Content {
              description
              title
              id
            }
          }

          ... on GLSTRAPI_ComponentModulesStepsModule {
            __typename
            id
            steps {
              show_logo
              title
              description
            }
            contents {
              description
              title
              id
            }
          }

          ... on GLSTRAPI_ComponentBlocksTextBoxInsideTextBox {
            __typename
            id
            content {
              id
              title
              description
            }
            sub_text_boxes {
              description
              id
              title
            }
          }

          ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {
            __typename
            id
            layout_type
            image_tag
            video_url
            Button_CTA {
              custom_link
              id
              label
              target
              theme
              menu_item {
                slug
                link_type
                label
                id
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
            }
            media_block {
              id
              media_file {
                url
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 486
                      width: 516
                      layout: FIXED
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
            text_block {
              description
              title
            }
          }

          ... on GLSTRAPI_ComponentComponentsGridBlock {
            __typename
            id
            Grid_Description
            Grid_Style
            Grid_Title
            Grid_Items {
              Heading
              Title
              id
              video_url
              grid_link {
                id
                label
                link_type
                slug
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                  }
                }
              }
              Grid_Image {
                url
                alternativeText
                url_sharp {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 580
                      layout: FIXED
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
