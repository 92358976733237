import React from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./TextBlockSlider.scss"

import ScrollSliderModule from "./ScrollModule"
import CommonSlider from "../CommonSlider/CommonSlider"
import Slider from "react-slick"

const TextBlockSlider = ({ module }) => {


  let settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    centerMode: false,
    infiniteScroll: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
          dots: true,
          infiniteScroll: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          infiniteScroll: true,
        },
      },
    ],
  }

  const moduleDivID = module?.content?.title
    ?.split(" ")
    .join("-")
    .replaceAll("?", "")

  return (
    <div className="text-block-slider-wrapper" id={moduleDivID}>
      <Container className="text-block-container">
        <div className="content">
          <h2 className="title">{module?.content?.title}</h2>
          {module?.content?.description && (
            <div className="description">
              {parse(module?.content?.description)}
            </div>
          )}
        </div>
        <ScrollSliderModule sub_text_boxes={module.sub_text_boxes} />
        <Slider className="slider-dots-container" {...settings}>
          {module.sub_text_boxes?.map(item => (
            <div className="slide-wrap">
              <p className="title">{item.title}</p>
              {item.description && (
                <div className="description">{parse(item.description)}</div>
              )}
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  )
}

export default TextBlockSlider
