import React from "react"
import ReactSlider from "react-slider"
import ellipse from "../../images/icons/ellipse.svg"
import parse from "html-react-parser"

const ScrollSliderModule = ({ sub_text_boxes }) => {
  if (!(sub_text_boxes?.length > 0)) return null

  return (
    <div className="scroll-wrapper">
      <div id="scroll-container">
        {sub_text_boxes?.map(item => (
          <div className="slide-wrap">
            <img src={ellipse} className="list-icon"></img>
            <div className="">
              <p className="title">{item.title}</p>
              {item.description && (
                <div className="description">{parse(item.description)}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="slider-wrapper">
        <ReactSlider
          renderThumb={(props, state) => (
            <div {...props} className="slider-thumb"></div>
          )}
          onChange={val => {
            const element = document.getElementById("scroll-container")
            const width = (element.offsetWidth - 760) / 100
            element.style.left = `-${val * width}px`
          }}
          className="slider"
        />
      </div>
    </div>
  )
}

export default ScrollSliderModule
