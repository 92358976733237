import React, { useEffect, createRef, useState } from "react";
import { AddressFinder } from "@ideal-postcodes/address-finder";

export default function AddressLookup() {
    const inputId = "line_1";

    const [address, setAddress] = useState({
        line_1: "",
        line_2: "",
        line_3: "",
        post_town: "",
        postcode: "",
    });

    React.useEffect(() => {
        AddressFinder.setup({
            inputField: document.getElementById(inputId),
            detectCountry: false,
            defaultCountry: "GBR",
            restrictCountries: ["GBR"],
            apiKey: "ak_kbv58axqhS72sCqyn9vhBXsdnaRvk",
            queryOptions: {
                country: ["Wales", "England"]
            },
            injectStyle: true,
            /* ... */
            onAddressRetrieved: ({ line_1, line_2, line_3, post_town, postcode }) =>
                setAddress({ line_1, line_2, line_3, post_town, postcode }),
            /* ... */
        });
    }, []);

    return (
        <div className="App">
            <input
                type="text"
                id={inputId}
                //value={`${address.line_1}, ${address.line_2}`}
                value={address.line_1}
                className="form-control address-input"
                onChange={(e) => setAddress({ ...address, line_1: e.target.value })}
                placeholder={`Property postcode or area`}
                required
            />
            <div className="d-none">
            <label>Address Line One</label>
            <input
                type="text"
                value={address.line_1}
                onChange={(e) => setAddress({ ...address, line_1: e.target.value })}
                name="address1"
            />
            <label>Address Line Two</label>
            <input
                type="text"
                value={address.line_2}
                onChange={(e) => setAddress({ ...address, line_2: e.target.value })}
                name="address2"
            />
            <label>Address Line Three</label>
            <input
                type="text"
                value={address.line_3}
                onChange={(e) => setAddress({ ...address, line_3: e.target.value })}
                name="address3"
            />
            <label>Post Town</label>
            <input
                type="text"
                value={address.post_town}
                onChange={(e) => setAddress({ ...address, post_town: e.target.value })}
                name="address4"
            />
            <label>Postcode</label>
            <input
                type="text"
                value={address.postcode}
                onChange={(e) => setAddress({ ...address, postcode: e.target.value })}
                name="address5"
            />
            </div>
        </div>
    );
}
