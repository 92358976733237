import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { GetPageURL } from "../common/utils"
import parse from "html-react-parser"
import "./ListBlockModule.scss"

const ListBlocksModule = ({ module }) => {

  return (
    <div className="section-p list-block-wrapper">
      <Container className="list-block-container">
        <div className="content">
          <h2 className="title">{module.Grid_Title}</h2>
          {module.Grid_Description && <p className="description">{parse(module.Grid_Description)}</p>}
        </div>
        <div className="list-block">
          {module.Grid_Items?.length > 0 &&
            module.Grid_Items.map(item => (
              <Link
                target={item?.target === "new_window" ? "_blank" : ""}
                to={GetPageURL(item.grid_link)}
                className="list-item rounded img-zoom"
              >
                <GetGatsbyImage
                  image={item.Grid_Image}
                  fallbackalt={item.Heading}
                  className="list-image"
                />
                {/* <img src={item.Grid_Image?.url} alt="" className="list-image" /> */}
                <div className="overlay" />
                <p className="cta-text">{item.Heading}</p>
              </Link>
            ))}
        </div>
      </Container>
    </div>
  )
}

export default ListBlocksModule
